import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientService } from './client/client.service';
import { locations } from '@prisma/client';

export interface LocationExtended extends locations {
  fileName?: string;
  photoAltText?: string;
  photo?: File;
}

@Injectable()
export class LocationsService extends ClientService {
  public getLocations(): Observable<LocationExtended[]> {
    return this.get<LocationExtended[]>(
      `${this.baseUrl}/api/locations`,
      undefined,
      {
        storageType: 'memory',
        ttlMS: 300000, // 5 minutes
      }
    );
  }

  public updateLocation(formValues: LocationExtended): Observable<any> {
    this.clearStorage();
    const formData: FormData = new FormData();
    formData.append('name', formValues.name);
    formData.append('activities', formValues.activities);
    formData.append('shortDescription', formValues.shortDescription);
    formData.append('description', formValues.description);
    formData.append('difficulty', formValues.difficulty);
    formData.append('duration', formValues.duration);
    formData.append('entrances', formValues.entrances);
    formData.append('viewingSite', formValues.viewingSite);
    formData.append('photoAltText', formValues.photoAltText);
    formData.append('fileName', formValues.fileName);
    if (formValues.order) {
      formData.append('order', formValues.order.toString());
    }

    // Append latitude and longitude if they are not null
    if (formValues.latitude != null) {
      formData.append('latitude', formValues.latitude.toString());
    }
    if (formValues.longitude != null) {
      formData.append('longitude', formValues.longitude.toString());
    }

    // Append active status if not null
    if (formValues.active != null) {
      formData.append('active', formValues.active.toString());
    }

    // Append locationId
    if (formValues.locationId != null) {
      formData.append('locationId', formValues.locationId.toString());
    }

    if (formValues.photo) {
      formData.append('file', formValues.photo, formValues.photo.name);
    }

    return this.http.post(`${this.baseUrl}/api/updateLocation`, formData);
  }

  deleteLocation(locationId: number) {
    this.clearStorage();
    const body = { locationId: locationId };
    return this.http.post<Response>(`${this.baseUrl}/api/deleteLocation`, body);
  }
}
